import React from 'react';
import styled from '@emotion/styled';
import { theme, SectionTitle, SectionSubtitle } from '@styles';

const { breakpoints } = theme;

const ContentApiStep: React.FC<{
  step_title: string;
  step_subtitle: string;
  step_image: {
    url: string;
    alt: string;
  };
}> = (props) => {
  const { step_title, step_subtitle, step_image } = props;

  return (
    <Wrapper>
      <SectionTitle>{step_title}</SectionTitle>
      <SectionSubtitle>{step_subtitle}</SectionSubtitle>
      <ImageWrapper>
        <img src={step_image.url} alt={step_image.alt} />
      </ImageWrapper>
    </Wrapper>
  );
};

export default ContentApiStep;

const ImageWrapper = styled.div`
  border-radius: 18px;
  overflow: hidden;
  max-width: 743px;
  display: flex;
  margin: 0px 24px;
  img {
    width: 100%;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 0 auto;
    border-radius: 32px;
  }
`;

const Wrapper = styled.div`
  margin-top: 34px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 44px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
  }
`;
