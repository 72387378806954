import React from 'react';
import styled from '@emotion/styled';
import { theme } from '@styles';
import ContentApiStep from './ContentApiStep';
import { ContentApiStepsSectionData } from './types';

const { breakpoints } = theme;

const Steps: React.FC<ContentApiStepsSectionData> = ({ steps }) => {
  return (
    <Wrapper>
      {steps.map((step, index) => {
        return (
          <div key={index} style={{ marginBottom: '200px' }}>
            <ContentApiStep
              step_title={step.step_title}
              step_image={step.step_image}
              step_subtitle={step.step_subtitle}
            />
          </div>
        );
      })}
    </Wrapper>
  );
};

export default Steps;

const Wrapper = styled.div`
  margin-top: 34px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 44px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
  }
`;
