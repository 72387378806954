import React from 'react';
import styled from '@emotion/styled';
import { HeaderTitle, HeaderSubtitle, theme, SectionWrapper as SectionWrapperStyles } from '@styles';
import CustomText from '@components/CustomText';
import { ContentApiHeaderSectionData } from './types';
import { useWindowWidth } from '@customHooks';
import Button from '@components/Button';
const { breakpoints } = theme;

const Header: React.FC<ContentApiHeaderSectionData> = (props) => {
  const { title, subtitle, bottom_text, header_image, get_started_link, button_text } = props;
  const windowWidth = useWindowWidth();

  const breakpointSmallLaptop = 769;
  const breakpointIsMobile = windowWidth < breakpointSmallLaptop;

  return (
    <SectionWrapper>
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderSubtitle>{subtitle}</HeaderSubtitle>
      <ButtonContainer id='businessContact'>
        <Button
          id='get_started_api'
          onClick={() => {
            window.open(`${get_started_link}${window.location.search}`, '_blank');
          }}
          mr={'32px'}
          label={button_text}
          fontSize={breakpointIsMobile ? '14px' : '16px'}
          width={breakpointIsMobile ? 169 : 203}
          height={breakpointIsMobile ? 52 : 60}
        />
      </ButtonContainer>

      <HeaderImageContainer>
        <img src={header_image.url} alt='Header image' />
      </HeaderImageContainer>

      <BottomText>
        <CustomText fontFamily={'Poppins'} fontWeight={600} fontSize={20} lineHeight={'30px'} letterSpacing={'-0.1px'}>
          {bottom_text}
        </CustomText>
      </BottomText>
    </SectionWrapper>
  );
};

export default Header;

const SectionWrapper = styled(SectionWrapperStyles)`
  padding: 0 24px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    padding: initial;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const HeaderImageContainer = styled.div`
  margin: 56px auto 34px auto;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 40px auto;
    display: flex;
    justify-content: center;
  }
`;

const BottomText = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 60px;
  margin: 32px auto 0px auto;
  a {
    text-decoration: none;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    justify-content: center;
    margin-top: 48px;
  }
`;
