import React from 'react';
import styled from '@emotion/styled';
import { SectionTitle, SectionSubtitle, SectionWrapper } from '@styles';
import { Item } from '@components/ItemsGrid';
import ItemsGrid from '@components/ItemsGrid';
import { ContentApiBenefitsSectionData } from './types';

const BenefitsSection: React.FC<ContentApiBenefitsSectionData> = (props) => {
  const { benefits, benefits_title, benefits_subtitle } = props;
  const items: Item[] = benefits.map((item) => {
    return {
      title: item.benefit_title,
      description: item.benefit_subtitle,
      thumbnail: item.benefit_icon.url || 'https://via.placeholder.com/150',
    };
  });
  return (
    <SectionWrapper>
      <SectionTitle>{benefits_title.text}</SectionTitle>
      <SectionSubtitle>{benefits_subtitle.text}</SectionSubtitle>
      <ItemsWrapper>
        <ItemsGrid items={items} />
      </ItemsWrapper>
    </SectionWrapper>
  );
};

export default BenefitsSection;

const ItemsWrapper = styled.div`
  max-width: 930px;
  margin: 0 auto;
`;
