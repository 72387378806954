import React from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { PageContext, IPrismicContentApi } from '@appTypes';
import FooterSection from '@pageSections/common/footer';
import HeaderSection from '@pageSections/contentApiPage/header';
import Separator from '@components/Separator';

import BenefitsSection from '@pageSections/contentApiPage/benefits';
import FAQSection from '@pageSections/contentApiPage/faq';
import { Helmet } from 'react-helmet';
import Steps from '@pageSections/contentApiPage/steps';
import CallToActionSection from '@pageSections/common/callToAction';

function removeTags(htmlString: string) {
  if (htmlString === null || htmlString === '') return false;
  else htmlString = htmlString.toString();
  return htmlString.replace(/(<([^>]+)>)/gi, '');
}

const HomePage: React.FC<PageProps<IPrismicContentApi, PageContext>> = ({
  data: { prismicFooterData, prismicData, prismicCtaData },
  pageContext,
  location,
}) => {
  const { locale } = pageContext;
  const data = prismicData!.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url, faq_items } = data;

  const faqSchemaOrgJSONLD = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': faq_items.map((item: any) => {
      return {
        '@type': 'Question',
        'name': item.faq_question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': removeTags(item.faq_answer.html),
        },
      };
    }),
  };

  return (
    <>
      <SEO
        title={meta_title}
        description={meta_description}
        image={meta_image.url}
        url={meta_url}
        pathname={location.pathname}
        name='Hyperhuman'
        locale={locale}
      />
      <Helmet>
        <script type='application/ld+json'>{JSON.stringify(faqSchemaOrgJSONLD)}</script>
      </Helmet>
      <Container>
        <HeaderSection {...data} />
        <Separator />
        <Steps {...data} />
        <Separator />
        <BenefitsSection {...data} />
        <Separator />
        <FAQSection {...data} />
      </Container>
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} subscribeForm />
    </>
  );
};

export default HomePage;

const Container = styled.div`
  position: initial;
`;

export const pageQuery = graphql`
  query ContentApiQuery($locale: String!) {
    prismicData: allPrismicContentApi(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_description
            meta_image {
              url
            }
            meta_url
            meta_keywords
            title
            subtitle
            button_text
            get_started_link
            header_image {
              url
            }
            header_image_mobile {
              url
            }
            bottom_text
            steps {
              step_image {
                url
              }
              step_title
              step_subtitle
            }
            benefits_title {
              text
            }
            benefits_subtitle {
              text
            }
            benefits {
              benefit_icon {
                url
              }
              benefit_title
              benefit_subtitle
            }
            faq_title
            faq_subtitle
            faq_items {
              faq_question
              faq_answer {
                html
              }
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
